import React, { useState, useEffect, useLayoutEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LayoutNoStripe from "../../../components/layoutNoStripe";
import { SectionHeading } from "../../../styledComponents/section";
import ShowProgramfiles from "../../../components/Program/templates/showProgramFiles";
import { SectionAV } from "../../../styledComponents/ProgramAVContent";
import styled from "styled-components";
import { Spinner, SpinnerContainer } from "../../../styledComponents/spinner";
import CopyContent from "./copy";
import { ProgramNav } from "../../../styledComponents/programCrumbNav";
import { Link } from "gatsby";


const Heading = ({ level = 1, location, children, align }) => (
    <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
        {children}
    </SectionHeading>
);

const ProgramDivineReversalCodesProgram = ({ data, location }) => {
    const [roles, setRoles] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    const fetchRoles = async () => {
        if (!isAuthenticated) {
            setRoles([]);
            // setLoadingRoles(false)
            setTimeout(() => setLoadingRoles(false), 3000); // Force delay for testing
            return;
        }

        try {
            const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];

            if (userRoles.length > 0) {
                setRoles(userRoles);
            } else {
                const token = await getAccessTokenSilently({ ignoreCache: true });
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
                setRoles(tokenRoles);
            }
        } catch (error) {
            console.error("Error fetching roles:", error);
            setRoles([]);
        } finally {
            setTimeout(() => setLoadingRoles(false), 3000); // Force delay for testing
        }
    };

    useEffect(() => {
        fetchRoles();
        const interval = setInterval(() => {
            fetchRoles();
        }, 1000);
        return () => clearInterval(interval);
    }, [isAuthenticated, user, getAccessTokenSilently]);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useLayoutEffect(() => {
        if (typeof window !== "undefined" || window.document) {
            // Additional client-side logic if needed
        }
    }, []);

    // Helper function to check if user has a specific role
    const hasRole = (role) => roles.includes(role);

    return (
        <LayoutNoStripe displayNavBorder location={location}>
            {isAuthenticated && 
      <ProgramNav><Link to="/my-account">Back to My Account</Link></ProgramNav>
      }
            <SectionAV className="av-program">
                <div className="react-tabs">
                    <h2>Divine Reversal Codes</h2>
                    {loadingRoles ? (
                        <SpinnerContainer>
                            <Spinner />
                        </SpinnerContainer>
                    ) : (
                        <>
                            {hasRole("Divine Reversal Codes") ? (
                                <ShowProgramfiles id="cj2kdXox8DcR3X==" />
                            ) : (
                                <>
                                       <p style={{'marginTop':'2rem'}}>You need to <a href="/my-account">login</a> to access this program</p>
                                </>
                            )}
                        </>
                    )}
                </div>
            </SectionAV>
        </LayoutNoStripe>
    );
};

export default ProgramDivineReversalCodesProgram;
