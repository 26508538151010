import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "./tabs"
import { regular14, regular16, regular18, regular20, regular22 } from "./typography";

const ProgramNav = styled.nav`
  width: 100%;
  padding: 1.8rem 2.4rem 1.2rem;
  background: var(--grey-lightest);
  border-bottom: 1px solid var(--grey-light);
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 2rem 3rem;
  }
  a {
    text-decoration: none;
    ${regular14}
    :before {
    display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 0.6rem;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(135deg);
        position: relative;
        top: 0px;
      }

    &:visited {
      border-bottom: 1px solid var(--grey-lightest);
    }
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    a {
    ${regular18}
  }
  
`


export {
  ProgramNav
}